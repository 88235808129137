.nav-dropdown__mobile {
    position: absolute;
    display: none;
    z-index: 1000!important;
    right:0px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
    top:38px;
    background: var(--surface-card);
    width: 60%;
    list-style: none;
    margin: 0;
    padding: 2px;
}


.nav-dropdown__mobile:after {
    content: "";
    position: absolute;
    top: -9px;
    right: 2px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--surface-card);
}
