.p-scrollpanel-wrapper {
    border-right: 9px solid #ffffff;
}

.p-scrollpanel-bar {
    background-color: lightgrey!important;
    opacity: 1;
   transition: background-color .3s;
}

.p-scrollpanel-bar:hover {
    background-color: var(--main-accent-colour) !important;
}


.p-scrollpanel-content {
    padding: 0 0 18px 0  !important;
}


h4 {
    margin-left: 20px;
}

.vehicleButton:hover {
    background: #e0dfe2;
    transition: background-color 500ms;
}


.filterButton {
    padding: 0.8rem!important;
    justify-content: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: #673AB7;
    color: white;
    border:none;
    font-size: 18px;

}

.clearFilterBox {
    position: absolute;
    right: 50px;
    top: 11px;
    cursor: pointer;
    z-index: 10001;
}



