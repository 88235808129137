
#navbar {
    display: inline-flex;
    justify-content: center;
    width: 10em;
    height: 50px;
    padding: 5px 10px;
    font-size: 40px;
    list-style: none;
    color: var(--text-color);
    background-color: var(--surface-ground);
    overflow: hidden;

&.closed {
     width: 50px;
     border-radius: 50%;
     transition: width 0.1s linear, border 0.1s linear;
 }

&.opened {
     width: 9.5em;
     border-radius: 10% / 50%;

     transition: width 0.1s linear, border 0.1s ease;

.menu-item {
    margin-left: 0.3em;
    margin-right: 0.3em;
    visibility: visible;

&.main {
     animation-name: spin;
     animation-duration: 600ms;
     animation-iteration-count: 1;
     animation-timing-function: ease;
     animation-fill-mode: both;
 }
}
}
}

.menu-item {
    margin: 0;
    padding: 2px 5px;
    transition: width 0.3s linear, visibility 0.1s ease 0.3s;

.i {
    margin-top: 3px;
    font-size: 26px;
    vertical-align:top;
    text-align: center;
    color: var(--text-color);
}
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
