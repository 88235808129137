.wall{
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/18515/PIA09959-1280x800_copy.jpg);
    background-size: cover;
}
.scene{
    display: inline-block;
    vertical-align: middle;
    perspective: 5px;
    perspective-origin: 50% 50%;
    position: relative;
}

.wrap{
    position: absolute;
    width: 1000px;
    height: 1000px;
    left: -500px;
    top: -500px;
    transform-style: preserve-3d;
    animation: move 12s infinite linear;
    animation-fill-mode: forwards;

}

.wrap:nth-child(2){
    animation: move 12s infinite linear;
    animation-delay: 6s;
}

.wall {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    animation: fade 12s infinite linear;
    animation-delay: 0;
}

.wrap:nth-child(2) .wall {
    animation-delay: 6s;
}

.wall-right {
    transform: rotateY(90deg) translateZ(500px);
}

.wall-left {
    transform: rotateY(-90deg) translateZ(500px);
}

.wall-top {
    transform: rotateX(90deg) translateZ(500px);
}

.wall-bottom {
    transform: rotateX(-90deg) translateZ(500px);
}

.wall-back {
    transform: rotateX(180deg) translateZ(500px);
}

@keyframes move {
    0%{
        transform: translateZ(-500px) rotate(0deg);
    }
    100%{
        transform: translateZ(500px) rotate(0deg);
    }
}

@keyframes fade {
    0%{
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}












































/*
.scene {
  animation: 🤫 300s infinite linear;
}

@keyframes 🤫 {
  0%{
    filter: hue-rotate(0)
  }
  50% {
    filter: hue-rotate(180deg) saturation(5);
    transform: scaleX(4000);
    perspective: 50px;
  }

  100%{
    filter: hue-rotate(360deg);
  }
}
*/
