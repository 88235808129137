.template .p-button.playSelected {
    background: linear-gradient(to left, var(--green-500) 50%, #5C8459 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: white;
    border-color: var(--green-300);
    padding: 0.79rem 1rem;
}
.template .p-button.playSelectedDisabled {
    background: linear-gradient(to left, var(--green-500) 50%, #5C8459 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    background-opacity: 0.5;
    transition: background-position 0.5s ease-out;
    color: white;
    border-color: var(--green-300);
    padding: 0.79rem 1rem;
    cursor: not-allowed;
}
.template .p-button.playSelected:hover {
    background-position: left bottom;
}
.template .p-button.playSelected:focus {
    box-shadow: 0 0 0 1px #5C8459;
}

.p-button-success {
    background: #5C8459!important;
    border: solid 1px #5C8459;
    padding: 0.79rem 1rem;
}

.p-button-danger {
    padding: 0.79rem 1rem;
}

.p-button-secondary {
    color: black!important;
    border: 1px solid #64748B!important;
    background: #d3d1d1!important;
}

.stream::-webkit-media-controls {
    display:none !important;
}


