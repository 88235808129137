@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

body {
  margin: 0!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  /*position: fixed;*/
}

body:not(input){
  /*-webkit-user-select: none;*/
  /*-ms-user-select: none;*/
  /*user-select: none;*/
}



html {
  height: 100%;
  font-size: 13px;
  font-family: 'Roboto Condensed', sans-serif;
}

button {
  font-family: 'Roboto Condensed', sans-serif!important;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}

.menuBarlogo {
  margin: 10px;
/*  image-rendering: high-quality;
  transform: translateZ(0);*/
}

.content {
  flex: 1;  /* 1 and it will fill whole space left if no flex value are set to other children*/
  overflow: hidden;
  display: flex;
  flex-flow: column;
}

footer {
  border-top: solid 1px var(--surface-border);
  height:40px!important;
  display: flex;
  align-items:center;
  justify-content: space-between;
  padding: 5px 20px 5px 20px;
  position: absolute;
  bottom:0;
  left:0;
  z-index:500;
  width: 100%
}
/*.ui-widget {*/
/*  font-family: 'Segoe UI', Arial, sans-serif;*/
/*  font-size: 1em;*/
/*}*/
.ui-widget .ui-widget {
  font-size: 1em;
}

root {
  font-size: 12px;
  /*font-family: 'Segoe UI', Arial, sans-serif;*/
}

/* login page */
.loginContainer {
  display: flex;
  height:100%;
  margin:0!important;
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}
@media (min-width: 767px) {
  .hidden-md-up {
    display: none !important;
  }
}


/* vehicle list */


.vehicleListEntry {
  display:flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px 5px 8px;
  height: 92px;
  overflow: hidden;
  background: transparent;
  transition: background .5s, color .1s;
}


.vehicleListEntry:hover {
  background: #e9e9e9;
  color: #000000;
}



/* driverBehaviour modal */
.eventClassificationContainer{
  width: 100px;
  height: 50px;
  border-radius: 40px;
  text-align: center;
  position: relative;
  border:1px solid lightgray;
  border-top: none;
  padding:8px;
  /*margin-left: auto;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.eventClassificationTitle {
  position: absolute;
  top:-8px;
  left:18px;
  margin-left: auto;
  width: 60px;
  font-size: 12px;
  font-weight: bold;
}


.eventSeverityCircle {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  border-radius: 40px;
}


.dateSelectorAddonBtn {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.38);
  background: #ffffff;
  border-radius: 4px;
  padding: 8px 5px;
}
.dateSelectorAddonBtn:hover{
  background: rgba(0, 0, 0, 0.0005);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)!important;
  border-radius: 10px!important;
  background-color: var(--surface-ground)!important;
}
::-webkit-scrollbar {
  width: 6px!important;
  height: 6px!important;
  background-color: var(--surface-ground)!important;
  }

::-webkit-scrollbar-thumb {
  border-radius: 10px!important;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)!important;
  background-color: #d3d3d3!important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--main-accent-colour)!important;
}


.p-password input {
  width: 15rem;
}

.p-button-danger {
  color: #ffffff;
  background: #EF4444;
  border: 1px solid #EF4444;
}


.p-datascroller.p-datascroller-inline .p-datascroller-list > li {
  border:none!important;
}


.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.ui-tooltip {
  pointer-events: none;
}

.p-dialog .p-dialog-content {
  margin-top: -1px;
}

.p-dialog-top .p-dialog, .p-dialog-bottom .p-dialog, .p-dialog-left .p-dialog, .p-dialog-right .p-dialog, .p-dialog-top-left .p-dialog, .p-dialog-top-right .p-dialog, .p-dialog-bottom-left .p-dialog, .p-dialog-bottom-right .p-dialog {
  margin:0!important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.p-slider.p-slider-horizontal {
  height: 5px!important;
}

.p-checkbox .p-checkbox-box {
  border-width:1px!important;
}

.hideNormalShowPrint {
  display: none;
}

.reportTotalBubble {
  flex: 1;
  border-radius: 5px;
  padding: 10px 5px 10px 5px;

  /*background-color: #d3d1d1!important;*/
  /*-webkit-print-color-adjust: exact;*/
  border: solid 1px var(--surface-border);
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  text-align:center;
  color: var(--text-color);
}












@media print {
  @page {
    size: landscape;
    overflow: visible!important;
    margin: 5mm !important;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  body * {
    visibility: hidden;
  }
  .section-to-print, .section-to-print * {
    visibility: visible;
    color: black!important;
  }

  .reportTable {
    font-size: 8px!important;
  }

  .section-to-print, .hideNormalShowPrint {
    display: block;
  }

  .hideOnPrint {
    display: none!important;
  }

  .section-to-print, .section-to-print img {
    width: 115px
  }
  .section-to-print, .section-to-print .scroller {
    height: auto !important;
    overflow: visible !important;
    page-break-inside: auto!important;

  }

  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    height: auto !important;
    overflow: visible!important;
    text-align: left;
    display: block;
  }
}

  .p-password input {
    width: 20rem;
  }

  .p-input-icon-left>i, .p-input-icon-right>i, .p-input-icon-left>svg, .p-input-icon-right>svg, .p-input-icon-left>.p-input-prefix, .p-input-icon-right>.p-input-suffix {
    color: black!important;
  }


  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: rgba(206, 147, 216, 0.12);
  }


  .p-progressbar-label {
    display: none!important;
  }

.tStyle {
    height: 38.67px;
    padding: 1rem 0.5rem;
    margin: 3px;
    background: var(--surface-300);
    font-size: 11px;
    color: var(--text-color);
    display: inline-flex;
    cursor: pointer;
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
    border: none;
    border-radius: 2px;
}
.tStyleSelected {
    padding: 1rem 0.5rem;
    margin: 3px;
    background: var(--gray-700);
    font-size: 11px;
    color: white;
    display: inline-flex;
    cursor: pointer;
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
    border: none;
    border-radius: 2px;
  }

.p-input-icon-right > i:last-of-type, .p-input-icon-right > svg:last-of-type, .p-input-icon-right > .p-input-suffix {
  color: var(--text-color)!important;
}

.loginPosterContainer {
  padding: 0!important;
  background: var(--surface-ground);
}

.confirmPopupWarn {
  background: #FFECB3;
  color: #7f6003;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}


.p-fieldset .p-fieldset-legend {
  background: none!important;
  border: none!important;
}

.p-button {
  border: none!important;
}

.p-button:focus {
  box-shadow: none!important;
}

.eventModal > .p-dialog-header{
  display: none
}

.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.62475rem 0.875rem;
}

.eventFilterButton {
  padding: 0.4rem 0.875rem!important;
  min-width: 69px!important;
}
.p-button-label{
  font-weight: normal!important;
}

.p-calendar-w-btn .p-datepicker-trigger.p-button {
  color: var(--text-color)!important;
}