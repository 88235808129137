
body {
    margin: 0;
    padding: 0;
    background-color: var(--surface-ground);
    color: var(--text-color);
}

.nav-bar {
    height: 72px;
    background-color: var(--surface-ground);
    color: var(--text-color);
    border-bottom: solid 1px var(--surface-border);
    position:absolute;
    top:0;
    left:0;
    width:100%!important;
    z-index:500;
}

.brand {
    position: absolute;
    padding-left: 20px;
    float: left;
    line-height: 70px;
    text-transform: uppercase;
    font-size: 1.4em;
    padding-top:15px;
    cursor:pointer;
}

.nav-container {
    /*   max-width: 1250px; */
    width:100%!important;
    margin: 0 auto;
}

nav {
    float: right;
}
nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
nav ul li {
    float: left;
    position: relative;
}
nav ul li a {
    display: block;
    line-height: 20px;
    background-color: var(--surface-ground)!important;
    color: var(--text-color);
    text-decoration: none;
    text-align:center;
    padding: 21px 25px 0;
    cursor:pointer;
    font-weight:bold;
}

nav ul li span {
    display: block;
    line-height: 25px;
    background-color: var(--surface-card);
    color: var(--text-color);
    text-decoration: none;
    text-align:center;
    padding-top: 5px;
    padding-bottom: 5px;
}

nav ul li > .logout {
    cursor:pointer;
    font-weight:bold;

}

nav ul li > .logout:hover {
    background: #d1d1d1;
    color: black;
}

nav ul li a:hover {
    background: rgba(0,0,0,0.05);
    border-bottom: 3px solid grey
}

nav ul li div ul li {
    min-width: 190px;
}
nav ul li div ul li a {
    padding: 15px;
    line-height: 22px;
}



nav ul li ul li:first-child span {
    /*border-radius: 5px 5px 0px 0px;*/
    /*border-top: solid 1px grey;*/
    /*border-right: solid 1px grey;*/
    /*border-left: solid 1px grey;*/
    /*background-color: var(--surface-ground);*/
    /*color: var(--text-color);*/
}

nav ul li ul li:last-child span {
    /*border-radius: 0px 0px 5px 5px;*/
    /*border-bottom: solid 1px grey;*/
    /*border-right: solid 1px grey;*/
    /*border-left: solid 1px grey;*/
}

/*nav ul li ul li:not(:first-child):not(:last-child) {*/
/*    border-right: solid 1px grey;*/
/*    border-left: solid 1px grey;*/
/*}*/

.nav-dropdown {
    position: absolute;
    display: none;
    z-index: 100000000!important;
    right:10px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
    top: 82px;
}




.nav-mobile {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--surface-ground);
    height: 70px;
    width: 70px;
}

@media only screen and (min-width: 800px ){
    .nav-dropdown:before {
        content: "";
        position: absolute;
        top: -9px;
        right: 18px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid grey;
    }

    .nav-dropdown:after {
        content: "";
        position: absolute;
        top: -8px;
        right: 20px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid var(--surface-card);
    }
}





@media only screen and (max-width: 798px) {
    .nav-mobile {
        display: block;
    }
    nav {
        width: 100%;
        padding: 70px 0 15px;
    }
    nav ul {
        display: none;
    }
    nav ul li {
        float: none;
        margin-top: -1px
    }
    nav ul li a {
        padding: 15px;
        line-height: 20px;
        padding-left: 15%;
        height:100%

    }

    nav > ul > li > ul > li > span {
        padding: 15px;
        line-height: 20px;
        padding-left: 25%;
    }

    .nav-dropdown {
        position: static;
        right: 0;
        }

}
@media screen and (min-width: 799px) {
    .nav-list {
        display: block !important;
    }
}
#nav-toggle {
    position: absolute;
    left: 18px;
    top: 22px;
    cursor: pointer;
    padding: 10px 35px 16px 0px;
}
#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 2px;
    width: 35px;
    background: var(--text-color);
    position: absolute;
    display: block;
    content: '';
    transition: all 300ms ease-in-out;
}
#nav-toggle span:before {
    top: -10px;
}
#nav-toggle span:after {
    bottom: -10px;
}
#nav-toggle.active span {
    background-color: transparent;
}
#nav-toggle.active span:before, #nav-toggle.active span:after {
    top: 0;
}
#nav-toggle.active span:before {
    transform: rotate(45deg);
}
#nav-toggle.active span:after {
    transform: rotate(-45deg);
}
