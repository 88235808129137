.icon:hover {
    fill: red;
}


.journeyBlock:hover {
    fill: #8C8C8C;
    transition: fill 300ms;
}


.journeyBlock {
    fill: #b8b7b8;
}



.journeyToolTip {
    background: var(--surface-card);
    color: var(--text-color);
    display: inline;
    position: absolute;
    padding: 3px 10px 3px 10px;
    border-radius: 6px;
    white-space: nowrap;
}


.journeyToolTip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--surface-card) transparent;
}


.p-inputtext {
    padding: 5px !important;
}
